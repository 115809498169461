@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.form-container {
  display: flex;
  flex-direction: column;
  background: #c0e8ff;
  justify-content: space-between;
  font-family: "Be Vietnam Pro";
  padding: 64px;
  width: 60%;
  min-height: 100%;
}

.topic-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.content {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.content2 {
  display: flex;
  column-gap: 32px;
  align-items: center;
}

.topic-title {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 70px;
  text-align: center;
  color: #0e7ee4;
  margin-bottom: 40px;
}

.input {
  width: 744px;
  height: 40px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  display: block;
  font-size: 16px;
  padding: 10px;
  width: 100%;
}

.input::placeholder {
  padding-left: 16px;
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #788796;
}

.font {
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
}

.sub-title-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 47px;
  color: #212529;
}

.sub-title {
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  font-size: 22px;
  line-height: 35px;
  color: #494b58;
}

.but {
  width: 136px;
  height: 40px;
  background: #0e7ee4;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.but:hover {
  opacity: 0.9;
}

.next {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
}

.itemend {
  margin-top: 32px;
  align-self: flex-end;
}

.quotation-form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.itemstart {
  margin-top: 32px;
  align-self: flex-start;
}

.select {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(14, 126, 228, 0.6);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;

  display: flex;
  align-items: center;

  color: #0e7ee4;
  padding-left: 16px;
}

.select option {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #0e7ee4;
}

.box {
  display: flex;
  justify-content: space-between;
}

.shownum {
  font-family: "Be Vietnam Pro";
  font-weight: 700;
  font-size: 32px;
  line-height: 47px;
  color: #ea663f;
}
