@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.left-container {
  display: flex;
  flex-direction: column;
  padding: 64px;
  width: 40%;
  align-items: flex-start;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  row-gap: 24px;
}

.Logo {
  width: 64px;
  margin-right: 16px;
}

h3 {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 47px;

  color: #0a1936;
}
.alto {
  width: 138px;
  height: 52px;
}

.nextn1 {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;

  color: #494b58;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.nextsteptitle {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;

  color: #494b58;
  width: calc(100% - 50px - 40px);
}

.nextellipse8 {
  /* position: absolute; */
  width: 40px;
  height: 40px;
  padding: 8px;

  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.steprow {
  display: flex;
  column-gap: 24px;
  row-gap: 72px;
  align-items: center;
  width: 100%;
  /* justify-content: center; */
}

.currentsteptitle {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 38px;
  color: #0e7ee4;
  width: calc(100% - 50px - 40px);
}

.currentellipse8 {
  width: 40px;
  height: 40px;
  padding: 8px;

  background: #ffffff;
  border: 2px solid #0e7ee4;
  box-sizing: border-box;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.currentn1 {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;

  color: #0e7ee4;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.prevsteptitle {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  color: #bcc5cd;
  width: calc(100% - 50px - 40px);
}

.prevellipse8 {
  width: 40px;
  height: 40px;
  padding: 8px;

  background: #1edb31;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.prevn1 {
  text-align: center;
  justify-content: center;
  width: 100%;
}
